.contact-form-container {
  max-width: 600px;
  margin: 5% auto;
  padding: 20px;
  background-color: #f9f9f9;
  /*border-radius: 8px;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 5px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group.full-width {
  flex-basis: 100%;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  /*border-radius: 4px;*/
  font-size: 1em;
}

.form-group input[type="text"],
.form-group input[type="email"] {
  flex-basis: calc(50% - 10px); /* Two items in one row */
}

.form-group textarea {
  height: 100px;
}

.submit-row {
  display: flex;
  justify-content: flex-start;
}

.submit-row button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  /*border-radius: 4px;*/
  cursor: pointer;
  font-size: 1em;
}

.submit-row button:hover {
  background-color: #0056b3;
}
