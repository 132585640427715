.navigation-bar {
  background: linear-gradient(
    90deg,
    #050912 0.1%,
    #0d121e 33.37%,
    #0d121e 66.63%,
    #050912 99.9%
  ) !important;
}

.brand-logo {
  width: 61px;
  height: 40px;
  margin-right: 10px;
}

.navigation-bar-2 .nav-link {
  color: #ffffffb2 !important;
  margin-right: 15px;
  font-size: 1rem;
}

.navigation-bar-2 .nav-link:hover,
.navigation-bar-2 .nav-link:focus,
.navigation-bar-2 .nav-link:active {
  color: white !important;
}

/* Apply border-bottom only on large screens and above */
@media (min-width: 992px) {
  .navigation-bar-2 .nav-link:hover,
  .navigation-bar-2 .nav-link:focus,
  .navigation-bar-2 .nav-link:active {
    color: white !important;
    border-bottom: 2px solid #f7f6fd;
  }
}

.contact-us-header {
  display: flex;
  align-items: center;
}

.navbar-contact-us-btn {
  background-color: #141414 !important;
  border: 1px solid #760a23 !important;
  color: white !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 1rem !important;
  border-radius: 15px !important;
}

.navbar-contact-us-btn:hover {
  background: linear-gradient(to right, #ff416c, #1c92d2) !important;
  border-color: transparent !important;
  color: white !important;
}

/* Highlight the active section link */
.navigation-bar-2 .nav-link.active {
  color: #ff416c !important;
  border-bottom: 2px solid #ff416c;
  font-weight: bold;
}

Custom
  styles
  for
  dropdown
  menu
  items
  .navigation-bar-2
  .dropdown-menu
  .dropdown-item:active,
.navigation-bar-2 .dropdown-menu .dropdown-item:focus {
  background-color: #000000 !important;
  color: #ffffff !important;
}

/* Show dropdown menu on hover */
/*.navigation-bar-2 .dropdown:hover .dropdown-menu {*/
/*  display: block;*/
/*  visibility: visible;*/
/*  opacity: 1;*/
/*}*/

/* Hide the dropdown initially */
/*.navigation-bar-2 .dropdown-menu {*/
/*  display: block;*/
/*  visibility: hidden;*/
/*  opacity: 0;*/
/*  transition: opacity 0.3s ease, visibility 0.3s ease;*/
/*}*/

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .nav-link {
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Optional: Adjust the dropdown menu position */
.dropdown-menu {
  margin-top: 0;
  display: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}
