.rotate {
  animation: spin 2s linear infinite;
  transform-origin: center center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.static {
  fill: white; /* Ensures the path does not change color */
}
