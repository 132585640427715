.hero-illustration {
  width: 100%;
  height: auto;
}

.hero-content {
  position: absolute;
  bottom: -33%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 100px;
  color: white;
  width: 100%;
}

.display-4 {
  font-size: 2rem;
}

.overlay-row {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  margin-top: 20%;
  width: 100%;
}

/* @media (max-width: 320px) {
  .hero-illustration {
    width: 100%;
  }

  .hero-content {
    bottom: -14%;
  }

  .display-4 {
    font-size: 2rem;
  }
} */

@media (min-width: 321px) and (max-width: 375px) {
  /* .hero-illustration {
    width: 100%;
  } */

  .display-4 {
    font-size: 1.8rem;
  }

  .hero-content {
    bottom: -25%;
  }

  .overlay-row {
    margin-top: 20% !important;
    left: 53%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  /* .hero-illustration {
    width: 100%;
  } */

  .hero-content {
    bottom: -14%;
  }

  .display-4 {
    font-size: 2rem;
  }

  .overlay-row {
    margin-top: 13% !important;
    left: 53%;
  }
}

@media (min-width: 426px) and (max-width: 450px) {
  /* .hero-illustration {
    width: 100%;
  } */

  .hero-content {
    /* padding-bottom: 50px; */
    bottom: -13%;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .overlay-row {
    margin-top: 0% !important;
    left: 53%;
  }
}

@media (min-width: 451px) and (max-width: 536px) {
  /* .hero-illustration {
    width: 100%;
  } */

  .hero-content {
    /* padding-bottom: 50px; */
    bottom: -7%;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .overlay-row {
    margin-top: 0% !important;
    left: 53%;
  }
}

@media (min-width: 537px) and (max-width: 600px) {
  /* .hero-illustration {
    width: 100%;
  } */

  .hero-content {
    /* padding-bottom: 50px; */
    bottom: 2%;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .overlay-row {
    margin-top: 0% !important;
    left: 53%;
  }
}

@media (min-width: 601px) and (max-width: 700px) {
  /* .hero-illustration {
    width: 100%;
  } */

  .hero-content {
    /* padding-bottom: 50px; */
    bottom: 14%;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .overlay-row {
    margin-top: -24% !important;
    left: 53%;
  }
}

@media (min-width: 701px) and (max-width: 769px) {
  .hero-illustration {
    width: 90%;
  }

  .hero-content {
    /* padding-bottom: 50px; */
    bottom: 14%;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .overlay-row {
    margin-top: -24% !important;
    left: 53%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .hero-illustration {
    width: 65%;
  }

  .hero-content {
    bottom: 12%;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .overlay-row {
    margin-top: -20% !important;
    left: 53%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .hero-illustration {
    width: 50%;
  }

  .hero-content {
    bottom: 8%;
  }

  .display-4 {
    font-size: 2.8rem;
  }

  .overlay-row {
    margin-top: -10% !important;
    left: 53%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .hero-illustration {
    width: 50%;
  }

  .hero-content {
    bottom: 18%;
  }

  .display-4 {
    font-size: 2.8rem;
  }

  .overlay-row {
    margin-top: -16% !important;
    left: 50%;
  }
}

@media (min-width: 1441px) and (max-width: 1660px) {
  .hero-illustration {
    width: 40%;
  }

  .hero-content {
    bottom: 9%;
  }

  .display-4 {
    font-size: 3.3rem;
  }

  .overlay-row {
    margin-top: -8% !important;
    left: 50%;
  }
}

@media (min-width: 1661px) and (max-width: 1999px) {
  .hero-illustration {
    width: 35%;
  }

  .hero-content {
    bottom: 7%;
  }

  .display-4 {
    font-size: 3.8rem;
  }

  .overlay-row {
    margin-top: -7% !important;
    left: 50%;
  }
}

@media (min-width: 2000px) {
  .hero-illustration {
    width: 40%;
  }

  .hero-content {
    bottom: 15%;
  }

  .display-4 {
    font-size: 5rem;
  }

  .overlay-row {
    margin-top: -10% !important;
    left: 50%;
  }
}
