/* Ensure that the counter section is displayed as a flex container in larger screens */
.main_counter_content {
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next line if they overflow */
}

.div-margin {
  margin-top: 7%;
}

/* .overlay-row {
  position: relative;
  top: 50%; 
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); 
  color: white;
  text-align: center;
  margin-top: -17%;
  width: 100%;
} */

.mt-5 {
  margin-top: 3rem !important;
}

/* .rfm-marquee-container {
  margin-top: -40%;
} */

/* .display-4 {
  font-size: 4rem;
} */

/*.hero-content {*/
/*    bottom: 28%;*/
/*}*/

/* Horizontal layout for desktop */
@media (min-width: 768px) {
  .main_counter_content {
    flex-direction: row; /* Arrange items horizontally */
  }

  .single_counter {
    margin-bottom: 0; /* Remove bottom margin in horizontal layout */
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  /* .hero-content {
    bottom: 5%;
  } */
  .my-p {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  .div-margin {
    margin-top: 91%;
  }

  /* .hero-illustration {
    width: 100%;
  } */
}

@media (min-width: 769px) and (max-width: 871px) {
  /* .hero-content {
    bottom: 20%;
  } */

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */
}

@media (min-width: 872px) and (max-width: 991px) {
  /* .hero-content {
    bottom: 25%;
  } */

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* .hero-content {
    bottom: 25%;
  } */

  .my-p {
    margin-top: -43%;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */
}

@media (min-width: 378px) and (max-width: 395px) {
  .div-margin {
    margin-top: 149%;
  }

  .text-mobile-left {
    text-align: left;
  }
  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%;
    padding-right: 1%;
  }
  /* .hero-content {
    bottom: -5%;
  } */

  .navbar-contact-us-btn {
    width: 100%;
  }

  .mt-5 {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */

  /* .display-4 {
    font-size: 2rem;
  } */

  /* .overlay-row {
    margin-top: 13% !important;
    left: 53%;
  } */
}

@media (min-width: 396px) and (max-width: 415px) {
  .div-margin {
    margin-top: 144%;
  }

  .text-mobile-left {
    text-align: left;
  }
  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%;
    padding-right: 1%;
  }

  /* .hero-content {
    bottom: -3%;
  } */

  .navbar-contact-us-btn {
    width: 100%;
  }

  .mt-5 {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */

  /* .display-4 {
    font-size: 2rem;
  } */

  /* .overlay-row {
    margin-top: 13% !important;
    left: 53%;
  } */
}

@media (min-width: 1030px) and (max-width: 1199px) {
  /* .hero-content {
    bottom: 29%;
  } */
  /* .display-4 {
    font-size: 4.5rem;
  } */
}

@media (min-width: 416px) and (max-width: 490px) {
  .text-mobile-left {
    text-align: left;
  }
  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%;
    padding-right: 1%;
  }
  /* .hero-content {
    bottom: 2%;
  } */

  .navbar-contact-us-btn {
    width: 100%;
  }

  .mt-5 {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */

  /* .display-4 {
    font-size: 2rem;
  } */

  /* .overlay-row {
    margin-top: 3% !important;
    left: 53%;
  } */

  .div-margin {
    margin-top: 138%;
  }
}

@media (min-width: 491px) and (max-width: 527px) {
  .text-mobile-left {
    text-align: left;
  }
  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%;
    padding-right: 1%;
  }
  /* .hero-content {
    bottom: 13%;
  } */

  .navbar-contact-us-btn {
    width: 100%;
  }

  .mt-5 {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */

  /* .display-4 {
    font-size: 2.5rem;
  } */

  /* .overlay-row {
    margin-top: 3% !important;
    left: 53%;
  } */

  .div-margin {
    margin-top: 111%;
  }
}

@media (min-width: 528px) and (max-width: 576px) {
  .text-mobile-left {
    text-align: left;
  }
  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%;
    padding-right: 1%;
  }
  /* .hero-content {
    bottom: 13%;
  } */

  .navbar-contact-us-btn {
    width: 100%;
  }

  .mt-5 {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */

  /* .display-4 {
    font-size: 3rem;
  } */

  /* .overlay-row {
    margin-top: 3% !important;
    left: 53%;
  } */

  .div-margin {
    margin-top: 111%;
  }
}

@media (min-width: 577px) and (max-width: 646px) {
  .text-mobile-left {
    text-align: left;
  }
  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%;
    padding-right: 1%;
  }
  /* .hero-content {
    bottom: 13%;
  } */

  .navbar-contact-us-btn {
    width: 100%;
  }

  .mt-5 {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */

  /* .display-4 {
    font-size: 3rem;
  } */

  /* .overlay-row {
    margin-top: 3% !important;
    left: 53%;
  } */

  .div-margin {
    margin-top: 111%;
  }
}

@media (min-width: 647px) and (max-width: 768px) {
  .text-mobile-left {
    text-align: left;
  }
  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%;
    padding-right: 1%;
  }
  /* .hero-content {
    bottom: 19%;
  } */

  .navbar-contact-us-btn {
    width: 100%;
  }

  .mt-5 {
    margin-top: 0;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  /* .hero-illustration {
    width: 100%;
  } */

  /* .display-4 {
    font-size: 3.5rem;
  } */

  /* .overlay-row {
    margin-top: -20% !important;
    left: 53%;
  } */

  .div-margin {
    margin-top: 78%;
  }

  .stats-container {
    transform: translateY(-3%);
  }
}
@media (min-width: 1550px) and (max-width: 1780px) {
  /* .hero-content {
    bottom: 25%;
  } */
}
@media (min-width: 1780px) and (max-width: 1880px) {
  /* .hero-content {
    bottom: 27%;
  } */
}

@media (min-width: 1881px) {
  /* .hero-content {
    bottom: 25%;
  } */
  /* .display-4 {
    font-size: 5rem;
  } */
}
/* Vertical layout for mobile */
@media (max-width: 767px) {
  .main_counter_content {
    flex-direction: column; /* Arrange items vertically */
    text-align: center; /* Center text for vertical layout */
  }

  .single_counter {
    display: flex;
    /*padding: 28px var(--sds-size-space-0);*/
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;
    background: #0e1422;
    color: #fff;
  }
}

.dot-background {
  width: 100%; /* Full width on smaller devices */
  height: 6.5rem; /* Can remain constant unless you want it to adjust too */
  margin-left: auto; /* Automatically center on small screens */
  margin-right: auto; /* Automatically center on small screens */
}

.p-y-2 {
  padding-top: 28px;
  padding-bottom: 28px;
}

.p-y-3 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.m-b-1 {
  margin-bottom: 18px;
}

.m-t-1 {
  margin-top: 18px;
}

.col-md-4 {
  padding: 0 1%;
}

.main_counter_area {
  background-size: cover;
  overflow: hidden;
}

.main_counter_area .main_counter_content .single_counter {
  display: flex;
  /*padding: 28px var(--sds-size-space-0);*/
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border-radius: 12px;
  background: #0e1422;
  color: #fff;
}

.stats-container {
  transform: translateY(-60%);
}

.main_counter_area .main_counter_content .single_counter i {
  font-size: 36px;
}

.position-absolute {
  position: absolute !important;
  top: 39%;
  left: 8%;
}

/*mobile devices*/
@media (max-width: 375px) and (max-width: 400px) {
  .single_counter h3 {
    font-size: 1.5rem;
  }

  .single_counter p {
    font-size: 1rem;
  }

  .single_counter i {
    font-size: 2rem;
  }

  .dot-background {
    width: 100%; /* Adjust width for small screens */
    /*margin-left: 10%; !* Center on larger screens *!*/
  }

  /* .display-4 {
    font-size: 3rem;
  } */

  .position-absolute {
    left: 32%;
  }

  .py-5 {
    padding-left: 6%;
  }

  .col-8 {
    flex: 1 0 auto;
  }

  .text-mobile-left {
    text-align: left;
  }

  .mt-5-why-us {
    margin-left: 5%;
  }

  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%; /* Optional: If you want to maintain equal spacing on both sides */
    padding-right: 1%; /* Optional: If you want to maintain equal spacing on both sides */
  }
}

/*Mobile screen only */

@media only screen and (min-width: 400px) and (max-width: 480px) {
  .single_counter h3 {
    font-size: 1.5rem;
  }

  .single_counter p {
    font-size: 1rem;
  }

  .single_counter i {
    font-size: 2rem;
  }

  .dot-background {
    width: 100%;
    height: 6.5rem;
    margin-left: 0%;
  }

  .position-absolute {
    left: 32%;
  }

  .py-5 {
    padding-left: 6%;
  }

  .col-8 {
    flex: 1 0 auto;
  }

  .text-mobile-left {
    text-align: left;
  }

  .mt-5-why-us {
    margin-left: 5%;
  }

  .custom-mobile-padding {
    margin-left: 1% !important;
    padding-left: 1% !important;
    margin-right: 1%; /* Optional: If you want to maintain equal spacing on both sides */
    padding-right: 1%; /* Optional: If you want to maintain equal spacing on both sides */
  }
}

@media only screen and (max-width: 767px) {
  .single_counter h3 {
    font-size: 1.75rem;
  }

  .single_counter p {
    font-size: 1.1rem;
  }

  .single_counter i {
    font-size: 2.5rem;
  }

  .main_counter_content {
    padding: 10px;
  }

  .stats-container {
    transform: translateY(-5%);
  }

  .dot-background {
    width: 100%; /* Adjust width for medium screens like tablets */
  }

  .position-absolute {
    left: 21%;
  }
}

/*tablet screen only*/

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .single_counter h3 {
    font-size: 1.75rem;
  }

  .single_counter p {
    font-size: 0.9rem;
  }

  .single_counter i {
    font-size: 2.5rem;
  }

  .main_counter_content {
    padding: 10px;
  }

  .dot-background {
    width: 100%; /* Original width on large screens */
  }

  .position-absolute {
    left: 21%;
  }
}

/*macbook screen only */

/*@media only screen and (min-width: 1025px) and (max-width: 1447px) and (max-height: 800px) {*/
/*    .dot-background {*/
/*        width: 100%;*/
/*        height: 5rem;*/
/*        margin-left: 0;*/
/*    }*/

/*    .position-absolute {*/
/*        position: absolute !important;*/
/*        top: 38%;*/
/*        left: 8%;*/
/*    }*/

/*    .display-4 {*/
/*        font-size: 4rem !important;*/
/*    }*/
/*}*/

@media (max-width: 430px) {
  .stats-container {
    transform: translateY(-4%);
  }
}

.container1 {
  max-width: 80%;
}

/*.planet-border {*/
/*    width: 293.45px;*/
/*    height: 1px;*/
/*    flex-shrink: 0;*/
/*    background: linear-gradient(90deg, #FF3E6C 0%, rgba(255, 62, 108, 0.00) 100%);*/
/*    margin-bottom: 7%;*/
/*}*/
