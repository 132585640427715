body {
  font-family: "Kumbh Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%; /* Full width for responsiveness */
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .hero-section {
  background-color: #111; 
  padding: 100px 0;
} */

.hero-wrapper {
  position: relative;
  text-align: center;
  width: 100%; /* Ensures the hero section takes the full width */
  padding: 0; /*  */
}

/* .hero-illustration {
  width: 60%;
  height: auto;
} */

/* .hero-content {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 100px;
  color: white;
  width: 100%;
} */

.my-p {
  margin: 0 auto;
}

/* Adjustments for mobile devices */
@media (max-width: 768px) {
  /* .hero-content {
    padding-bottom: 50px;
    bottom: -30%;
  } */

  .my-p {
    margin-top: 10%;
  }

  .px-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.move-left {
  margin-left: -10%; /* Adjust this value to move the image further left */
}

@media (max-width: 576px) {
  /* .hero-content {
    padding-bottom: 20px;
    bottom: -8%;
  } */

  /* .hero-illustration {
    width: 90%;
  } */

  .my-p {
    margin-top: 35%;
  }

  .px-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .move-left {
    margin-left: -19%; /* Adjust for a different look on smaller screens */
  }
}

.highlight {
  color: #ff4081; /* Pinkish highlight color */
}

.gradient-border-button-learn {
  background-color: #ffffff !important; /* Dark background */
  border: 2px solid #37a728 !important;
  /* border-image: linear-gradient(to right, #ff416c, #1c92d2) !important; */
  /* border-image-slice: 1 !important; */
  color: rgb(0, 0, 0) !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 1.5rem !important;
  border-radius: 15px !important;
}

.gradient-border-button-learn:hover {
  background: linear-gradient(
    to right,
    #ff416c,
    #1c92d2
  ) !important; /* Gradient background on hover */
  border-color: transparent !important; /* Hide border when hovered */
  color: white !important;
}

.gradient-border-button-carousal {
  background-color: #ffffff !important; /* Dark background */
  border: 2px solid #37a728 !important;
  /* border-image: linear-gradient(to right, #ff416c, #1c92d2) !important; */
  /* border-image-slice: 1 !important; */
  color: rgb(0, 0, 0) !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 1rem !important;
  border-radius: 15px !important;
  z-index: 500;
}

.gradient-border-button-carousal:hover {
  background: linear-gradient(
    to right,
    #ff416c,
    #1c92d2
  ) !important; /* Gradient background on hover */
  border-color: transparent !important; /* Hide border when hovered */
  color: white !important;
}

.gradient-border-button-career {
  background-color: #ffffff !important; /* Dark background */
  border: 2px solid #37a728 !important;
  /* border-image: linear-gradient(to right, #ff416c, #1c92d2) !important; */
  /* border-image-slice: 1 !important; */
  color: rgb(0, 0, 0) !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 0.9rem !important;
  border-radius: 15px !important;
  font-weight: normal !important;
  z-index: 500;
}

.gradient-border-button-career:hover {
  background: linear-gradient(
    to right,
    #ff416c,
    #1c92d2
  ) !important; /* Gradient background on hover */
  border-color: transparent !important; /* Hide border when hovered */
  color: white !important;
}

.custom-border {
  width: 250px; /* Adjust the width as needed */
  height: 2px; /* Thickness of the line */
  background: linear-gradient(
    to right,
    #ff416c,
    #1c92d2
  ) !important; /* Line color (matches the highlight color) */
  margin: 0 auto; /* Center align the line */
  margin-bottom: 50px; /* Space between the line and the following content */
}

.logos-partners {
  display: flex;
  padding: 54px var(--sds-size-space-0);
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.partners-logos img {
  max-height: 50px;
  margin: 10px;
}

.footer {
  background: linear-gradient(
    90deg,
    #050912 0.1%,
    #0d121e 33.37%,
    #0d121e 66.63%,
    #050912 99.9%
  );
}

.hero-container {
  position: relative;
}

.hero-section {
  background: linear-gradient(
    90deg,
    #050912 0.1%,
    #0d121e 33.37%,
    #0d121e 66.63%,
    #050912 99.9%
  );
  /*padding: 100px 0;*/
  position: relative;
  z-index: 1;
  /*padding-bottom: 70px; !* Ensures space for the stats to overlap *!*/
}

.dots-image {
  position: relative;
  margin-top: -36px; /* Adjust this to control how much of the stats overlap the dots image */
}

.stats-container {
  /* position: absolute; */
  /* top: 100%;
    left: 0;
    right: 0; */
  z-index: 2;
  transform: translateY(-10%);
}

@media (min-width: 992px) {
  .stats-container {
    transform: translateY(-70%);
  }
}

.marquee-width {
  width: 75% !important;
}

@media (max-width: 768px) {
  .marquee-width {
    width: 100% !important;
  }
}

.stats-card {
  background: #0e1422; /* Dark background similar to hero section */
  border-radius: 8px;
  padding: 40px 20px; /* Increased padding for more height */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 3;
  flex: 1; /* Ensures cards take equal width */
  min-width: 250px; /* Increased minimum width */
  max-width: 350px; /* Increased maximum width */
  min-height: 130px; /* Increased height */
  margin: 0 20px; /* Increased space between cards */
  color: white; /* Text color */
}

.stats-card h3 {
  font-size: 2.5rem; /* Increased font size */
  font-weight: bold;
  margin-bottom: 0px; /* Space between title and text */
  color: white; /* Ensures title text is white */
}

.stats-card p {
  margin-bottom: 10px;
  font-size: 1.2rem; /* Increased font size for subheading */
  opacity: 0.9; /* Slightly lighter text for subheading */
  color: white; /* Ensures subheading text is white */
}

.stats {
  display: flex;
  justify-content: space-around;
  align-items: center; /* Vertically center the content inside the cards */
}

/* SECTION TWO */
.enterprise-messaging-section {
  padding: 60px 0;
  /*margin-bottom: 250px;*/
  background-color: #f7f6fd;
  position: relative;
}

.enterprise-messaging-section-eight {
  padding: 0px 0;
  margin-bottom: 150px;
  background-color: #f7f6fd;
  position: relative;
}

/*.carousal-item-spacing {*/
/*    padding-right: 100px !important;*/
/*}*/

.gradient-text {
  font-weight: medium;
  letter-spacing: 2px;
  background: linear-gradient(180deg, #ff416c, #1c92d2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.enterprise-messaging-title {
  font-size: 36px;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.list-unstyled p {
  font-size: 18px;
  color: #000;
  display: inline;
  margin-left: 10px;
}

.image-stack {
  position: relative;
  height: auto;
}

.image-top {
  position: absolute;
  top: 0;
  left: 39%;
  z-index: 3;
}

.image-middle {
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 2;
}

.image-bottom {
  position: absolute;
  top: 100px;
  right: 0px;
  z-index: 1;
}

.dots-image-container {
  position: absolute;
  /*bottom: -50px;*/
  left: 0;
  right: 0;
  z-index: 0;
}

.dots-image-container img {
  width: 100%;
}

.carousel-indicators {
  bottom: -30px;
}

.carousel-indicators li {
  background-color: #ff7f50;
  border: none;
}

.carousel-indicators .active {
  background-color: #1a1a1a;
}

/* SECTION FOUR */

/* CSS */
/* CSS */
.sf-section-four {
  position: relative;
  padding: 40px 0;
  margin-bottom: 7%;
}

.sf-overlap {
  position: relative;
  z-index: 1;
}

.sf-frame,
.sf-frame-2 {
  background-color: #fff; /* Adjust background color if needed */
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sf-img,
.sf-group-2 {
  /* max-width: 100%; */
  height: 48px;
  width: 40px;
}

.sf-text-wrapper {
  font-weight: bold;
  margin-top: 15px;
  font-size: 1.5rem;
}

.sf-p {
  font-size: 1.2rem; /* Adjust font size as needed */
  color: #333;
  margin-top: 10px;
}

.sf-dots-image-container {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 0;
}

.sf-dots-image-container img {
  width: 100%;
}

/* SECTION SIX */
.sx-section-six {
  position: relative;
  padding: 40px 0;
}

.sx-overlap {
  position: relative;
  z-index: 1;
}

.sx-frame {
  background-color: #fff; /* Adjust background color if needed */
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  text-align: center; /* Center text and image */
  height: 100%;
}

.sx-img {
  max-width: 100%;
  height: auto;
}

.sx-text-wrapper {
  font-weight: bold;
  margin-top: 15px;
}

.sx-p {
  font-size: 1rem; /* Increased font size */
  color: #333;
  margin-top: 10px;
}

.sx-dots-image-container {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 0;
}

.sx-dots-image-container img {
  width: 100%;
}

.text-footer {
  color: #8c8c8c;
}

.homepage-container {
  display: flex;
  width: 100%;
  max-width: 874px;
  height: 64px;
  padding: 0 13px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .homepage-container {
    height: auto; /* Adjust height for smaller screens */
    padding: 0 8px; /* Adjust padding for smaller screens */
    gap: 16px; /* Adjust gap for smaller screens */
  }
}

@media (max-width: 480px) {
  .homepage-container {
    flex-direction: column; /* Stack items vertically on very small screens */
    height: auto;
    padding: 0 4px;
    gap: 12px;
  }
}

.dot-background {
  height: 100px;
  width: 100%;
  background-image: url("assets/img/dots.svg");
  background-repeat: repeat;
  background-size: cover;
}

.mt-5 {
  margin-bottom: 4rem !important;
}

.animated-text {
  color: white; /* Set the color of the plain text */
}

.animated-word {
  animation: color-change 5s ease-in-out infinite;
}

@keyframes color-change {
  0% {
    color: #ff5858;
  }
  25% {
    color: #ff8c58;
  }
  50% {
    color: #ffc858;
  }
  75% {
    color: #ffd7b8;
  }
  100% {
    color: #ffc8c8;
  }
}

.responsive-svg {
  max-width: 150px; /* Default max width */
}

@media (max-width: 768px) {
  .responsive-svg {
    max-width: 120px; /* Smaller SVG for tablets */
  }
}

@media (max-width: 576px) {
  .responsive-svg {
    max-width: 100px; /* Smaller SVG for phones */
  }
}

@media (max-width: 360px) {
  .responsive-svg {
    max-width: 80px; /* Even smaller SVG for smaller phones */
  }
}
